/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dots': {
    width: 16,
    height: 4,
    viewBox: '0 0 16 4',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4 2a2 2 0 10-4 0 2 2 0 004 0zm6 0a2 2 0 10-4 0 2 2 0 004 0zm4-2a2 2 0 110 4 2 2 0 010-4z" _fill="#9FB0C9"/>'
  }
})
